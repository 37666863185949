import React from "react";
import { useDispatch } from "react-redux";
import { OKCancel } from "../../../../../common-deprecated/components/modals/aem/ModalActionViews";
import ModalBaseView from "../../../../../common-deprecated/components/modals/aem/ModalBaseView";
import * as Modal from "../../../../../common-deprecated/styles/v2/toyota/globals/Modal";
import * as Type from "../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import * as Text from "../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { CompareUSCDispatchType, useCompareUSCSelector } from "../../../redux/store";
import { usedCompareRemoveCars } from "../../../../../common-deprecated/features/compare/redux/actions/UsedCarCompareExternalDataActions";
import { DeleteCarType } from "../../../../../shared-logic/features/compare/utils/modalConstants";
import { CompareUSCLabel, useCompareUSCLabel } from "../../../utils/labels";
import { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";

const ToyotaDeleteCarModal = (props: ModalViewType<DeleteCarType>): JSX.Element | null => {
    const { show, close, modalSettings } = props;
    const { indexToDelete } = modalSettings;
    const dispatch = useDispatch<CompareUSCDispatchType>();
    const usedCars = useCompareUSCSelector((state) => state.usedCarCompareExternalData?.cars || []);
    const labelParts = useCompareUSCLabel("compareModalDeleteCar").split("{carToDelete}");
    const [compareModalRemoveButtonLabel, compareModalCancelButtonLabel] = useCompareUSCLabel([
        "compareModalRemoveButton",
        "compareModalCancelButton",
    ]);

    // In case you remove the last car the value will be null as this car won't be available once removed
    // This value will stay null during the closing animation of this car index
    // As the modal is kept in the DOM the value will also be null during the opening animation of the next car index
    if (!usedCars[indexToDelete]) return null;

    const showLabel = usedCars[indexToDelete].product.brand.description && usedCars[indexToDelete].product.model;

    return (
        <ModalBaseView onClose={close} show={show} size="sm">
            <Modal.Header buttonAlignment="left">
                <Modal.Button type="button" onClick={close} lightBg hasText>
                    <IconWrapper>
                        <Icon variant="arrowLeft" />
                    </IconWrapper>
                    <Text.Button>
                        <CompareUSCLabel label="compareSelectBack" />
                    </Text.Button>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                {showLabel && (
                    <Type.Text headingType="base">
                        {labelParts[0] || ""}
                        <Type.Text headingType="base" styleType="headline">
                            {`${usedCars[indexToDelete].product.brand.description} ${usedCars[indexToDelete].product.model}`}
                        </Type.Text>
                        {labelParts[1] || ""}
                    </Type.Text>
                )}
            </Modal.Body>
            <OKCancel
                onApprove={() => {
                    dispatch(usedCompareRemoveCars([indexToDelete]));
                    close();
                }}
                approveLabel={compareModalRemoveButtonLabel}
                onCancel={close}
                cancelLabel={compareModalCancelButtonLabel}
            />
        </ModalBaseView>
    );
};

export default ToyotaDeleteCarModal;
