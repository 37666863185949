import React, { SyntheticEvent } from "react";
import { useDispatch } from "react-redux";
import PdfLink from "./PdfLink";
import CompareTable from "../common/CompareTable";
import { CompareUSCDispatchType, useCompareUSCSelector } from "../../redux/store";
import { CompareUSCLabel } from "../../utils/labels";
import { getUscVehicleUrlInfoFromCompareResult } from "../../utils/usedSpecsUtils";
import { getUscUrl } from "../../../shared-logic/utils/uscUtils";
import * as CompareItem from "./styles/CompareItem";
import CompareActions from "./styles/CompareActions";
import { trackAemUsedCompareCtaClick } from "../../utils/tracking";
import {
    LoadedUsedCompareCarType,
    UsedCompareCarType,
} from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import * as Preloader from "../../../../common-deprecated/styles/toyota/Preloader";
import ButtonPrimary from "../../../../common-deprecated/components/aem/toyota/ButtonPrimary";
import ButtonSecondary from "../../../../common-deprecated/components/aem/toyota/ButtonSecondary";
import { getContactDealerCta } from "../../utils/ctaUtils";

const UsedCtaContainer = (): JSX.Element => {
    const dispatch = useDispatch<CompareUSCDispatchType>();
    const cars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars);
    const commonSettings = useCompareUSCSelector((state) => state.commonSettings);
    const settings = useCompareUSCSelector((state) => state.compareUscSettings);
    const hidePrintButton = useCompareUSCSelector((state) => state.compareUscSettings.hidePrintButton);

    const getCarUrl = (car: UsedCompareCarType, pageUrl: string): string => {
        if (car.loading) return "";
        const urlInfo = getUscVehicleUrlInfoFromCompareResult(car);
        return getUscUrl(commonSettings, pageUrl, "used", urlInfo);
    };

    /**
     * Generates contactDealerCta data for the specific car
     * @param car the specific car to generate a button for
     * @returns an object containing a click event & a label
     */
    const getContactDealerCtaForCar = (
        car: LoadedUsedCompareCarType,
    ): {
        click: ((event: SyntheticEvent) => void) | null;
        label: string;
    } | null => {
        return getContactDealerCta(
            car,
            settings.urlUsedCarsDetailPage,
            commonSettings,
            settings,
            car.dealer,
            dispatch,
            () => trackAemUsedCompareCtaClick("uc-vehicle-request"),
        );
    };

    return (
        <>
            <CompareTable
                items={cars}
                columnChild={(car) => {
                    if (car.loading) {
                        return (
                            <Preloader.Wrapper width={1} position="relative">
                                <Preloader.default />
                            </Preloader.Wrapper>
                        );
                    }

                    const { label, click } = getContactDealerCtaForCar(car) || {};

                    return (
                        <CompareItem.Wrapper>
                            <CompareActions>
                                {settings.enableContactDealerForm && label && click && (
                                    <ButtonPrimary onClick={click}>{label}</ButtonPrimary>
                                )}
                                <ButtonSecondary
                                    href={getCarUrl(car, settings.urlUsedCarsDetailPage)}
                                    onClick={() => {
                                        trackAemUsedCompareCtaClick("uc-detail-page");
                                    }}
                                >
                                    <CompareUSCLabel label="usedCompareButtonSeeCar" />
                                </ButtonSecondary>
                            </CompareActions>
                        </CompareItem.Wrapper>
                    );
                }}
            />
            {!hidePrintButton && <PdfLink />}
        </>
    );
};

export default UsedCtaContainer;
