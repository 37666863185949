import { ModalMapType } from "../../../../../common-deprecated/types/CommonTypes";
import ToyotaDeleteCarModal from "./DeleteCarModal";
import ToyotaReplaceCarModal from "./ReplaceCarModal";
import { aemToyotaCommonModalMap } from "../../../../../common-deprecated/components/modals/aem/modals";
import {
    CompareV2ModalIdsType,
    MODAL_DELETE_CAR,
    MODAL_REPLACE_CAR,
} from "../../../../../shared-logic/features/compare/utils/modalConstants";
import IntegratedUscFormModal from "../../../../shared-components/toyota/modals/integrated-modal/v1/IntegratedUscFormModal";
import { MODAL_USC_FORM } from "../../../../shared-logic/utils/integrated-modal/integratedModalConstants";

export const aemToyotaCompareUscModalMap: ModalMapType<CompareV2ModalIdsType> = {
    ...aemToyotaCommonModalMap,
    [MODAL_DELETE_CAR]: ToyotaDeleteCarModal,
    [MODAL_REPLACE_CAR]: ToyotaReplaceCarModal,
    [MODAL_USC_FORM]: IntegratedUscFormModal,
};
